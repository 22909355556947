:root {
  --container-width: 1170px;
}

body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}

@media (min-width: 900px) {
  body {
    --header-height: 5rem;
  }
}
.stripe-blog-illustration {
  padding-top: 1.4736842105em;
}
.stripe-blog-illustration img {
  display: block;
}

.stripe-blog-image-preview {
  background-color: #d5efff;
  max-height: 20em;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.stripe-blog-image-preview:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.25em;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25));
}
.stripe-blog-image-preview .content-narrow {
  margin-top: 2.9473684211em;
}
.stripe-blog-image-preview .blog-image img {
  margin: 0 auto;
  border: 1px solid #d5efff;
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.25);
}

body.blog .related-entries {
  margin-bottom: 4.4210526316em;
}
body.blog .hfeed {
  padding-top: 2.9473684211em;
}

.post-large aside {
  margin-top: 1.4736842105em;
  padding-top: 0.7368421053em;
  padding-bottom: 0.7368421053em;
  margin-bottom: 1.4736842105em;
  background-color: #f7fcff;
  color: #68757d;
  font-size: 0.9em;
  line-height: 1.4;
  padding: 1em;
}
@media (min-width: 900px) {
  .post-large aside {
    width: 36%;
    float: right;
    border-left: 2px solid #d5efff;
    margin-top: 0;
    margin-left: 1rem;
    font-size: 0.85em;
  }
}
.post-large aside > :first-child {
  margin-top: 0;
}

.banner-blog {
  padding-block: 4em;
}
.banner-blog .stack {
  --space: 1rem;
}
.banner-blog h1 {
  font-size: 2.7em;
  line-height: 1.2;
  text-wrap: pretty;
}
.banner-blog p {
  font-size: 1.4em;
  color: #d5efff;
  text-wrap: pretty;
}

.banner-tag .tag-content-image {
  display: block;
  margin: 0 auto -1em;
  padding-top: 1em;
  max-width: 8em;
  max-height: 8em;
}
@media (min-width: 900px) {
  .banner-tag .tag-content-image {
    padding-top: 0.5em;
  }
}

.scrollers-nav ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  list-style: none;
  padding: 0;
  margin: 0;
}
.scrollers-nav li {
  margin: 0;
  padding: 0;
  flex: 1 1 45%;
}
@media (min-width: 600px) {
  .scrollers-nav li {
    flex-basis: 30%;
  }
}
@media (min-width: 900px) {
  .scrollers-nav li {
    flex-basis: 14%;
  }
}
.scrollers-nav a {
  background-color: #65bef6;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.75em 0.5em;
  border-radius: 4px;
  text-decoration: none;
  color: #1d446c;
  text-align: center;
}
.scrollers-nav a:hover {
  background-color: #96D7FF;
  gap: 0;
}
.scrollers-nav a svg {
  color: #d5efff;
}
.scrollers-nav .search {
  background-color: #9cfff2;
}
.scrollers-nav .search:hover {
  background-color: #dcfffa;
}
.scrollers-nav .search svg {
  color: #447871;
}