@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../base/variables";
@import "../utils/mixins";

// Blog 
// ----------------------------------------------------------------

.stripe-blog-illustration {
  @include padding-leader;
  
  img {display: block; }
}

.stripe-blog-image-preview {
  background-color: palette(blue, x-light);
  max-height: 20em;
  padding: 0;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .25em;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.25));
  }

  .content-narrow {
    @include leader(2);
  }

  .blog-image img {
    margin: 0 auto;
    border: 1px solid palette(blue, x-light);
    box-shadow: 0 2px 3px 0px rgba(0,0,0,.25);
  }
}

body.blog {
  .related-entries {
    @include trailer(3);
  }

  // Hfeed
  // ----------------------------------------------------------------
  .hfeed {
    @include padding-leader(2);
  }
}


.post-large aside {
  @include rhythm(1, .5, .5, 1);
  background-color: palette(blue, xx-light);
  color: #68757d;
  font-size: .9em;
  line-height: 1.4;
  padding: 1em;

  @include breakpoint($lg) {
    width: 36%;
    float: right;
    border-left: 2px solid $divider-color;
    margin-top: 0;
    margin-left: 1rem;
    font-size: .85em;
  }

  > :first-child { margin-top: 0; }
}

// Blog Banner
// ----------------------------------------------------------------

.banner-blog {
  padding-block: 4em;

  .stack {
    --space: 1rem;
  }
  h1 {
    font-size: 2.7em;
    line-height: 1.2;
    text-wrap: pretty;
  }
  p {
    font-size: 1.4em;
    color: palette(blue, x-light);
    text-wrap: pretty;
  }
}

// Blog Tag Header
// ----------------------------------------------------------------

.banner-tag {
  .tag-content-image {
    display: block;
    margin: 0 auto -1em;
    padding-top: 1em;
    max-width: 8em;
    max-height: 8em;

    @include breakpoint($lg) {
      padding-top: .5em;
    }
  }
}


// Scrollers Nav
// ----------------------------------------------------------------
.scrollers-nav {

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin: 0;
    padding: 0;
    flex: 1 1 45%;

    @include breakpoint($med) {
      flex-basis: 30%;
    }
    @include breakpoint($lg) {
      flex-basis: 14%;
    }
  }

  a {
    background-color: palette(blue, light);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: .5em;
    padding: .75em .5em;
    border-radius: $default-border-radius;
    text-decoration: none;
    color: palette(blue, xx-dark);
    text-align: center;

    &:hover {
      background-color: #96D7FF;
      gap: 0;
    }

    svg {
      color: palette(blue, x-light);
    }
  }

  .search {
    background-color: palette(teal, light);

    &:hover {
      background-color: palette(teal, x-light);
    }
    svg {
      color: palette(teal, dark);
    }
  }
}